
export const onRouteUpdate = ({ location, prevLocation }) => { 
    // if (location.pathname.split('?').length > 0) {
    //   window.history.replaceState(null, '', location.pathname);
    // } 
  
    const params = new URLSearchParams(location.search);
    var parameter1 = params.get("transType");
  
    //console.log("pathname", location.pathname)
  
    const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "Kent";
    const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');
  
    // Residential Redirects
    if(location.pathname === "/properties/for-sale/" || location.pathname === "/properties/for-sale"){
      window.location.replace("/properties/for-sale/in-"+defaultArea_Slug+"/");
    }
  
    if(location.pathname === "/properties/to-rent/" || location.pathname === "/properties/to-rent"){
      window.location.replace("/properties/to-rent/in-"+defaultArea_Slug+"/");
    }

    if(location.pathname === "/prime-properties/for-sale/" || location.pathname === "/prime-properties/for-sale"){
      window.location.replace("/prime-properties/for-sale/in-"+defaultArea_Slug+"/");
    }
  
    if(location.pathname === "/prime-properties/to-rent/" || location.pathname === "/prime-properties/to-rent"){
      window.location.replace("/prime-properties/to-rent/in-"+defaultArea_Slug+"/");
    }
  
    //console.log("old pathname", prevLocation?.search?.indexOf("utm_source"))
  
  }